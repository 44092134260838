import { RoType } from "../interface/ro-type";
import { Utilities } from "./utilities";

export class RoTypeUtilities extends Utilities {

  static changed(a: RoType, b: RoType): boolean {
    return !this.equalString(a.code, b.code) || !this.equalString(a.type, b.type);
  }

}
