export enum PACKET_ITEM_TYPE {
  "labor" = 1,
  "part",
  "outsourced"
}

export interface PacketItem {
    "idLaborOrPart": string;
    /** Código de mão de obra */
    "MOCode": string;
    "qty": number;
    /** Required by fabric
     * 
     * 1 - true
     * 
     * 2 - false
     * 
     */
    "factory": 1 | 2 | 3;
    /** active by default
     * 
     * 1 - true
     * 
     * 2 - false
     * 
     * 3 - Item not present into packet, but added applying OS carte
     */
    "active": 1 | 2 | 3;
    "osType"?: string;
    "type": PACKET_ITEM_TYPE;
  }
  
  export interface Packet {
    _id?: string;
    "number": string;
    "name": string;
    /** @ref Labor or Part */
    "laborDefault": string;
    "factoryCode": string;
    "code": string;
    /** parcels amount */
    "qtyInstallments"?: number;
    "standardInstallment"?: number;
    /** calculate fees value after nth parcel */
    "interestAfter"?: number;
    "fees"?: number;
    "value": number;
    "items": Array<PacketItem>
  }
  
  export interface Review {
    readonly _id?: string;
    "name": string;
    "packets": Array<Packet>;
  }
  
  export interface Carte {
    _id?: string;
    /** Model of vehicle */
    model: string;
    /** Vehicle brand (same from company) */
    brand: string;
    /** vehicle serie */
    series: string;
    /** Aditional value percentage for fabric items */
    markup?: number;
    reviews: Array<Review>;
    osType?: string;

    readonly available?: number;
    readonly company?: string;
    readonly createdAt?: string;
    readonly updatedAt?: string;
    readonly __v?: number;
  }