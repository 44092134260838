// enum.ts

// Enumeração dos tipos de mensagens com IDs correspondentes
export enum ControlMessageType {
    Aniversariante = 1,
    PosVenda = 3,
    AbrirOrcamento = 4,
    SolicitacaoAtualizacao = 5,
    AbrirOS = 6,
    FecharOS = 7,
    PesquisaDeSatisfacao = 8
}
  