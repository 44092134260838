<h1 mat-dialog-title>
  {{ (data?.roType ? 'ACTION.RO_TYPE.UPDATE' : 'ACTION.RO_TYPE.ADD') | translate }}
</h1>
<form [formGroup]="form">
  <mat-dialog-content>
    <mat-form-field class="col-12 col-md-6">
      <mat-label>{{ 'PROPERTY.RO_TYPE.DESCRIPTION' | translate }}</mat-label>
      <input matInput
             required
             type="text"
             autocomplete="off"
             maxlength="100"
             formControlName="type" />
      <mat-error *ngIf="form.get('type').hasError('required')">
        {{ 'VALIDATION.REQUIRED_FIELD' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-12 col-md-6">
      <mat-label>Observção</mat-label>
      <textarea matInput
                maxlength="2000"
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="3"
                formControlName="observation"></textarea>
    </mat-form-field>

    <mat-form-field class="col-12 col-md-4">
      <mat-label>CPNJ para nota de peças</mat-label>
      <input matInput
             cnpj
             maxlength="18"
             formControlName="nfeCnpj"
             [matAutocomplete]="nfeAuto">
      <mat-autocomplete #nfeAuto="matAutocomplete">
        @for (company of myCompanies | filter : form.value.nfeCnpj : "cnpj"; track company) {
        <mat-option [value]="company.cnpj">
          <div>{{ company.cnpj }}</div>
          <small>{{ company.fancyName}}</small>
        </mat-option>
        }
      </mat-autocomplete>
      <mat-error *ngIf="form.get('nfeCnpj').errors">
        {{ form.get('nfeCnpj').errors | error | translate: form.get('nfeCnpj').errors }}
      </mat-error>
      <mat-hint *ngIf="form.hasError('controlsEquals')">
        Os CNPJ precisam ser diferentes
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="col-12 col-md-4">
      <mat-label>CPNJ para nota de serviços</mat-label>
      <input matInput
             cnpj
             maxlength="18"
             formControlName="nfseCnpj"
             [matAutocomplete]="nfseAuto">
      <mat-autocomplete #nfseAuto="matAutocomplete">
        @for (company of myCompanies | filter : form.value.nfseCnpj : "cnpj"; track company) {
        <mat-option [value]="company.cnpj">
          <div>{{ company.cnpj }}</div>
          <small>{{ company.fancyName}}</small>
        </mat-option>
        }
      </mat-autocomplete>
      <mat-error *ngIf="form.get('nfseCnpj').errors">
        {{ form.get('nfseCnpj').errors | error | translate: form.get('nfseCnpj').errors }}
      </mat-error>
      <mat-hint *ngIf="form.hasError('controlsEquals')">
        Os CNPJ precisam ser diferentes
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="col-12 col-md-4">
      <mat-label>Operação</mat-label>
      <mat-select formControlName="operation">
        @for (operation of data?.operations; track operation) {
        <mat-option [value]="operation?.id">
          {{ operation?.description }}
        </mat-option>
        }
        <mat-option>
          -- nenhuma
        </mat-option>
      </mat-select>
    </mat-form-field>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button aria-label="cancel"
            mat-raised-button
            color="accent"
            type="button"
            (click)="dialogRef.close()">
      {{ 'ACTION.SHARED.CANCEL' | translate }}
    </button>
    <button aria-label="save"
            mat-raised-button
            color="primary"
            type="button"
            (singleClick)="onSubmit()"
            [disabled]="form.invalid">
      {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
    </button>
  </mat-dialog-actions>
</form>