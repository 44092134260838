import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoType } from '../../../interface/ro-type';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RoTypeService } from '../../../service/ro-type.service';
import { LayoutService } from '../../../service/layout.service';
import { SnackbarService } from '../../../service/snackbar.service';
import { RoTypeUtilities } from '../../../class/ro-type-utilities';
import { DataService } from '../../../service/data.service';
import { combineLatest, filter, map, switchMap } from 'rxjs';
import { Utilities } from '../../../class/utilities';
import { Operation } from '../../../interface/operation';
import { UnsubscribeAll } from '../../../class/unsubscribe-all';

@Component({
  selector: 'app-ro-type-edit',
  templateUrl: './ro-type-edit.component.html',
  styleUrl: './ro-type-edit.component.scss'
})
export class RoTypeEditComponent extends UnsubscribeAll implements OnInit, OnDestroy {

  myCompanies = [];

  form: FormGroup = this.formBuilder.group({
    id: [''],
    type: ['', [Validators.required]],
    observation: [''],
    nfseCnpj: ['', [Utilities.validateCNPJ()]],
    nfeCnpj: ['', [Utilities.validateCNPJ()]],
    operation: ['', [Validators.required]]
  }, {
    validator: Utilities.distinctControls('nfseCnpj', 'nfeCnpj')
  });

  private roType: RoType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { roType: RoType, operations: Operation[] },
    public dialogRef: MatDialogRef<RoTypeEditComponent>,
    private roTypeService: RoTypeService,
    private layout: LayoutService,
    private snackbar: SnackbarService,
    private formBuilder: FormBuilder,
    private _dataservice: DataService
  ) {
    super();
    if (data && data.roType) {
      this.roType = data.roType;
    }
  }

  ngOnInit() {
    if (this.roType) {
      this.form.patchValue(this.roType)
    }
    this.dialogRef.backdropClick().subscribe(() => this.closeIfUnchanged());

    // on input nfse CNPJ, define default nfe CNPJ
    this.subs.push(
      this.form.get('nfseCnpj').valueChanges.subscribe(value => {
        if(value){
          this.form.get('nfeCnpj').setValue(this._dataservice.company.cnpj, { emitEvent: false });
        }
      }))
      
    // on input nfe CNPJ, define default nfse CNPJ
    this.subs.push(
      this.form.get('nfeCnpj').valueChanges.subscribe(value => {
        if(value){
          this.form.get('nfseCnpj').setValue(this._dataservice.company.cnpj, { emitEvent: false });
        }
      })
    )

    this.subs.push(this._dataservice.user$.pipe(
      filter(u => !!u),
      switchMap((user) => combineLatest([
        user.ownCompanies$,
        user.linkedCompanies$
      ])),
      map(([own, linked]) => own.concat(linked))
    ).subscribe((userCompanies) => this.myCompanies = userCompanies));
  }

  override ngOnDestroy(): void {
    this.unsubscribeAll()
  }

  async onSubmit() {
    this.layout.loader = true;
    let roType: RoType = this.form.getRawValue();
    try {
      if (roType.id) {
        await this.roTypeService.update(roType)
        this.snackbar.show('RO_TYPE.UPDATED', true, { code: roType.code });
      } else {
        const id = await this.roTypeService.add(roType);
        roType["id"] = id;
        this.snackbar.show('RO_TYPE.ADDED', true, { code: roType.code });
      }
      this.dialogRef.close(roType);
    } catch (error) {
      this.layout.loader = false;
      this.snackbar.show(roType.id ? 'RO_TYPE.NOT_UPDATED' : 'RO_TYPE.NOT_ADDED', false, { code: roType.code });
    } finally {
      this.layout.loader = false;
    }
  }

  @HostListener('window:keyup.esc')
  onKeyUp() {
    this.closeIfUnchanged();
  }

  private closeIfUnchanged() {
    if (!RoTypeUtilities.changed(this.roType, this.form.value)) {
      this.dialogRef.close();
    }
  }

}

